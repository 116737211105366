const AVATAR_URL_DIMENSION_FOLDER = '48';
const IMAGE_URL_DIMENSION_FOLDER = '256';

/**
 * Returns image url.
 *
 * @param {String} avatarUrl
 * @returns {String}
 */
export const getUserImageUrl = avatarUrl =>
  avatarUrl &&
  avatarUrl.replace(AVATAR_URL_DIMENSION_FOLDER, IMAGE_URL_DIMENSION_FOLDER);

/**
 *  Get fullname of user.
 *
 * @param {Object} user
 */
export const getFullName = user =>
  capitalize(user.firstName) +
  ' ' +
  (user.middleName ? capitalize(user.middleName) + ' ' : '') +
  capitalize(user.lastName);

/**
 * Capitalize String based on condition.
 *
 * @param {String} str
 */
export const capitalize = str =>
  str && str.replace(/(^|\s)\S/g, l => l.toUpperCase());
