import React from 'react';
import PropTypes from 'prop-types';

import TodaySingle from './TodaySingle';
import { birthdayPropType } from '../App';
import TodayMultiple from './TodayMultiple';

/**
 * Route Today Birthday based on no of Birthdays.
 *
 * @param {Object[]} users
 */
const BirthdaysToday = ({ users }) =>
  users.length === 1 ? (
    <TodaySingle user={users[0]} />
  ) : (
    <TodayMultiple users={users} />
  );

BirthdaysToday.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape(birthdayPropType))
};

export default BirthdaysToday;
