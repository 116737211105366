import config from '../config';

/**
 * Http GET util to fetch data with Authorization.
 *
 * @param {String} url
 * @returns {Promise}
 */
const get = async url => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `APIKEY ${config.apiKey}`
    }
  });

  return response.text();
};

export default { get };
