import React from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import withApi from './withApi';
import BirthdaysToday from './Today';
import BirthdaysUpcoming from './Upcoming';

const ROUTE_TODAY = '/';
const ROUTE_UPCOMING = '/upcoming';

/**
 * Routes app based on Birthday.
 *
 * @param {Object[]} birthdays
 * @returns {Component}
 */
const App = ({ birthdays }) => {
  const birthdaysToday = birthdays.filter(birthday => birthday.daysToGo === 0);
  const birthdaysUpcoming = birthdays
    .filter(birthday => birthday.daysToGo !== 0)
    .slice(0, 6);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path={ROUTE_UPCOMING}
          render={() => <BirthdaysUpcoming users={birthdaysUpcoming} />}
        />

        <Route
          path={ROUTE_UPCOMING}
          render={() => <Redirect to={ROUTE_UPCOMING} />}
        />

        <Route
          exact
          path={ROUTE_TODAY}
          render={() =>
            birthdaysToday.length > 0 ? (
              <BirthdaysToday users={birthdaysToday} />
            ) : (
              <Redirect to={ROUTE_UPCOMING} />
            )
          }
        />

        <Route
          path={ROUTE_TODAY}
          render={() => <Redirect to={ROUTE_TODAY} />}
        />
      </Switch>
    </Router>
  );
};

export const birthdayPropType = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  middleName: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  birthday: PropTypes.string.isRequired,
  daysToGo: PropTypes.number.isRequired
};

App.propTypes = {
  birthdays: PropTypes.arrayOf(PropTypes.shape(birthdayPropType))
};

export default withApi(App);
