import React from 'react';
import PropTypes from 'prop-types';

import { birthdayPropType } from '../App';
import { getFullName, getUserImageUrl } from '../utils/user';
import { ribbonTop, happyBirthdayBig } from '../images';
import Confetti from 'react-confetti';

/**
 * Component to display Single Birthday for current day.
 *
 * @param {Object} user
 */
const { width, height } = '100%';
const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  objectFit: 'fill'
};
const TodaySingle = ({ user }) => (
  <div className="wrapper container--center">
    <Confetti width={width} height={height} style={canvasStyles} />
    <div className="flags_top">
      <img src={ribbonTop} alt="Ribbon on top" className="img-fluid" />
    </div>
    <div className="d-flex">
      <div className="w-35">
        <img
          src={happyBirthdayBig}
          alt="Big Birthday Logo"
          className="img-fluid w-100"
        />
      </div>
      <div className="image-card image-card--flair">
        <div className="img-hldr mt-20">
          <img
            src={getUserImageUrl(user.avatarUrl)}
            alt="Profile"
            className="img-fluid w-400"
          />
        </div>
        <h2 className="birthday-name birthday-name--single max-width-unset">
          {getFullName(user)}
        </h2>
      </div>
    </div>
  </div>
);

TodaySingle.propTypes = {
  user: PropTypes.shape(birthdayPropType).isRequired
};

export default TodaySingle;
