import React from 'react';
import PropTypes from 'prop-types';

import { birthdayPropType } from '../App';
import { getFullName, getUserImageUrl } from '../utils/user';
import { ribbonTop, happyBirthdayUpcoming } from '../images';

/**
 * Wrapper component for upcoming Birthdays.
 *
 * @param {Object[]} users
 */
const Upcoming = ({ users }) => (
  <div className="wrapper ">
    <div className="flags_top">
      <img src={ribbonTop} className="img-fluid" alt="" />
    </div>
    <div className="row text-center mt-2">
      <div className="happy_birthday--upcoming">
        <img src={happyBirthdayUpcoming} className="img-fluid" alt="" />
      </div>
    </div>
    <div className="container--medium">
      <div className="row-f mt-2 text-center container--center">
        {usersDom(users)}
      </div>
    </div>
  </div>
);

/**
 * Component to display each user.
 *
 * @param {Object[]} users
 */
const usersDom = users =>
  users.map(user => (
    <div key={user.id} className="col-list col-list--upcoming">
      <div className="image-card image-card--flair">
        <div className="img-hldr">
          <img
            src={getUserImageUrl(user.avatarUrl)}
            alt="Profile"
            className="img-fluid img-fluid--pos"
          />
        </div>
        <h3 className="birthday-name">{getFullName(user)}</h3>
        <span className="date">{user.birthday}</span>
      </div>
    </div>
  ));

Upcoming.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape(birthdayPropType)).isRequired
};

export default Upcoming;
