import React from 'react';

import { loading } from './images';

/**
 * Loader Component.
 *
 * @returns {Component}
 */
const Loader = () => (
  <div className="loader-wrapper">
    <div className="loader">
      <img src={loading} alt="Loading" className="loading-icon-fluid" />
    </div>
  </div>
);

export default Loader;
