import React, { Component } from 'react';

import Loader from './loader';
import birthdayService from './services/birthday';

/**
 * HOC to fetch Birthday and inject to component.
 *
 * @param {Component} WrappedComponent
 * @returns {Component}
 */
function withApi(WrappedComponent) {
  class EnhancedComponent extends Component {
    constructor() {
      super();
      this.state = { birthdays: null, isLoading: true };
    }

    componentDidMount = async () => {
      try {
        const birthdays = await birthdayService.get();

        this.setState({
          isLoading: false,
          birthdays: birthdays
        });
      } catch (err) {
        // TODO: log the error
      }
    };

    render = () =>
      this.state.isLoading ? (
        <Loader />
      ) : (
        <WrappedComponent birthdays={this.state.birthdays} {...this.props} />
      );
  }

  return EnhancedComponent;
}

export default withApi;
