import React from 'react';
import PropTypes from 'prop-types';

import { birthdayPropType } from '../App';
import { getFullName, getUserImageUrl } from '../utils/user';
import { ribbonTop, happyBirthdaySmall } from '../images';
import Confetti from 'react-confetti';
import { object } from 'prop-types';

/**
 * Wrapper component to display multiple birthday for today.
 *
 * @param {Object[]} users
 */
const { width, height } = '100%';
const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  objectFit: 'fill'
};
const TodayMultiple = ({ users }) => (
  <>
    <div className="wrapper">
      <Confetti width={width} height={height} style={canvasStyles} />
      <div className="flags_top">
        <img src={ribbonTop} className="img-fluid" alt="" />
      </div>
      <div className="row text-center mt-2">
        <div className="happy_birthday--small">
          <img src={happyBirthdaySmall} className="img-fluid" alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row-f mt-2 text-center container--center">
          {usersDom(users)}
        </div>
      </div>
    </div>
  </>
);

/**
 * Component to display each user.
 *
 * @param {Object[]} users
 */
const usersDom = users =>
  users.map(user => (
    <div key={user.id} className="col-list">
      <div className="image-card image-card--flair">
        <div className="img-hldr">
          <img
            src={getUserImageUrl(user.avatarUrl)}
            alt="Profile"
            className="img-fluid img-fluid--pos"
          />
        </div>
        <h3 className="birthday-name">{getFullName(user)}</h3>
      </div>
    </div>
  ));

TodayMultiple.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape(birthdayPropType)).isRequired
};

export default TodayMultiple;
