import config from '../config';
import http from '../utils/http';

/**
 * Fetch Birthday's starting today.
 *
 * @returns {Object[]}
 */
const getBirthdays = async () => {
  const count = config.birthdayCount;

  const today = new Date();
  const todayDate = today.toISOString().slice(0, 10);

  const params = `?size=${count}&startDate=${todayDate}`;
  const url = config.apiUrl + params;

  const response = JSON.parse(await http.get(url));
  const birthdays = response.data;

  return birthdays;
};

export default {
  get: getBirthdays
};
